const UttarakhandImagData = [
  {
    id: 1,
    imgSrc: "UttarakhandImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "UttarakhandImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "UttarakhandImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "UttarakhandImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "UttarakhandImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "UttarakhandImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "UttarakhandImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "UttarakhandImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "UttarakhandImg9.jpg",
    imgAlt: "img9",
  },
];

export default UttarakhandImagData;
