import React, { useState } from "react";

const NepalContent = ({ id, question, answers }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="accordian">
      <div className="">
        <h2 className="mb-0">
          <div className="accordian-heading" onClick={() => setShow(!show)}>
            <div>
              {id === 1 ? (
                <>
                  <p>{question}</p>{" "}
                  <p className="accordian-icon">{show ? "+" : "-"}</p>
                </>
              ) : (
                <>
                  <p>{question}</p>{" "}
                  <p className="accordian-icon">{show ? "-" : "+"}</p>
                </>
              )}
            </div>
          </div>
        </h2>
      </div>
      {id === 1 ? (
        <>
          <div className={!show ? "accordian-body show" : "accordian-body"}>
            <div className="dropi"></div>
            {answers}
          </div>
        </>
      ) : (
        <>
          <div className={show ? "accordian-body show" : "accordian-body"}>
            <div className="dropi"></div>
            {answers}
          </div>
        </>
      )}
    </div>
  );
};

export default NepalContent;
