const DelhiImagData = [
  {
    id: 1,
    imgSrc: "/DelhiImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "/DelhiImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "/DelhiImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "/DelhiImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "/DelhiImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "/DelhiImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "/DelhiImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "/DelhiImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "/DelhiImg9.jpg",
    imgAlt: "img9",
  },
];

export default DelhiImagData;
