import React from "react";
import ShowcaseSwiper from "./ShowcaseSwiper";

const Showcase = () => {
  return (
    <section id="showcase">
      <div className="container">
        <div className="title py-5">
          <h4 className="title-primary">Image Showcase</h4>
          <h1 className="h1 h1-responsive title-secondary">Go & Discover</h1>
        </div>
      </div>
      {/* Add Swiper Image Here */}
      <ShowcaseSwiper />

      <div className="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <i className="fas fa-marker"></i>
                  <h4 className="card-title">Handpicked Hotels</h4>
                  <p className="card-text">
                    We offer many handpicked hotels that are assured by us.For
                    your break, whether you're looking for luxury country hotels
                    or an upmarket hotel.
                  </p>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{ cursor: "pointer" }}
                  >
                    Read More
                  </button> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <i className="fas fa-globe-asia"></i>
                  <h4 className="card-title">World Class Service</h4>
                  <p className="card-text">
                    We offer you things that we say to you. We always listen to
                    our customers requirements and always take care of their
                    need and comforts.
                  </p>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{ cursor: "pointer" }}
                  >
                    Read More
                  </button> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <i className="fas fa-rupee-sign"></i>
                  <h4 className="card-title">Best Price Guarantee</h4>
                  <p className="card-text">
                    We offer things on our Guarantee. We are assured on our
                    Price offers and which is lowest than others with best
                    services which no one can beat.
                  </p>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{ cursor: "pointer" }}
                  >
                    Read More
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
