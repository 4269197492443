/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const MarqueeStop = () => {
    return document.getElementById("mymarquee").stop();
  };
  const MarqueeStart = () => {
    return document.getElementById("mymarquee").start();
  };
  const [showHamBug, setShowHamBug] = useState(false);
  return (
    <>
      <header>
        <div className="top-header bg-secondary">
          <div className="container">
            <div className="top-nav">
              <ul>
                <li>
                  <i className="fas fa-phone-alt"></i>
                  <a href="tel:+917499999755" aria-label="Call Us">
                    +91 7499999755
                  </a>
                  ,{" "}
                  <a href="tel:+919919047413" aria-label="Call Us">
                    +91 9919047413
                  </a>
                  ,{" "}
                  <a href="tel:+918115588957" aria-label="Call Us">
                    +91 8115588957
                  </a>
                </li>
                <li>
                  <i className="fas fa-envelope"></i>
                  <a href="mailto:himjais7@gmail.com" aria-label="Email Us">
                    himjais7@gmail.com
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="/#Testimonial" aria-label="Testimonials">
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href="/aboutus" aria-label="About Us">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/contact" aria-label="Contact Us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          {/* <!-- Navbar --> */}
          <nav className="navbar navbar-expand-lg">
            {/* <!-- Container wrapper --> */}
            <div className="container-fluid">
              {/* <!-- Navbar brand --> */}
              <Link
                className="navbar-brand"
                to="/"
                style={{ color: "#ff6819" }}
              >
                Sai Travels & Catering
              </Link>

              {/* <!-- Toggle button --> */}
              <button
                className="navbar-toggler"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setShowHamBug(!showHamBug)}
                style={{ color: "#ff6819" }}
              >
                <i className="fas fa-bars"></i>
              </button>

              {/* <!-- Collapsible wrapper --> */}
              <div
                className={showHamBug ? "nav-check shown " : "nav-check"}
                id="navbarSupportedContent"
                onClick={() => setShowHamBug(!showHamBug)}
                style={{ cursor: "pointer" }}
              >
                {/* <!-- Left links --> */}
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="/#banner" aria-label="Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/#featured"
                      aria-label="Tours"
                    >
                      Tours in India
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/#international"
                      aria-label="International"
                    >
                      International Tours
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/#food" aria-label="Food">
                      Food Package
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/#transport"
                      aria-label="Transport"
                    >
                      Transport
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/contact"
                      aria-label="Support"
                    >
                      Support
                    </a>
                  </li>
                </ul>
                {/* <ul className="navbar-nav">
                  <li
                    className="nav-item"
                    style={{ listStyle: "none", cursor: "pointer" }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href="tel:+917499999755" style={{ color: "#000" }}>
                      +91 7499999755
                    </a>
                    ,{" "}
                    <a href="tel:+919919047413" style={{ color: "#000" }}>
                      +91 9919047413
                    </a>
                  </li>
                </ul> */}
              </div>
              {/* <!-- Collapsible wrapper --> */}
            </div>
            {/* <!-- Container wrapper --> */}
          </nav>
          {/* <!-- Navbar End --> */}
        </div>
      </header>

      {/* Marquee Section */}

      <div style={{ backgroundColor: "#e84118", color: "#fff" }}>
        <marquee
          id="mymarquee"
          className="li"
          direction="”right”"
          onMouseOver={MarqueeStop}
          onMouseOut={MarqueeStart}
          style={{ marginTop: "2px" }}
        >
          Varanasi Airport / Railway Station {"  "}
          <i class="fas fa-arrows-alt-h"></i> Pickup & Drop Available |{"  "}
          <i class="fas fa-phone-alt"></i>
          {"  "}
          <a
            href="tel:+917499999755"
            style={{ color: "#fff" }}
            aria-label="Call Us"
          >
            +91 7499999755
          </a>
          ,{" "}
          <a
            href="tel:+919919047413"
            style={{ color: "#fff" }}
            aria-label="Call Us"
          >
            +91 9919047413
          </a>
          ,{" "}
          <a
            href="tel:+918115588957"
            style={{ color: "#fff" }}
            aria-label="Call Us"
          >
            +91 8115588957
          </a>
        </marquee>
      </div>
    </>
  );
};

export default Navbar;
