import React from "react";

const PrayagrajData = [
  {
    id: 1,
    question: "4 Days - 3 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Gaya - Kashi <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival - Local Visit - Night
        Journey Gaya.
        <br />
        <b>2nd Day -</b> Gaya, Bodhgaya Local-Journey to Varanasi - Night Halt
        Varanasi.
        <br />
        <b>3rd Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating - Night
        Halt Varanasi.
        <br />
        <b>4th Day -</b> Morning Varanasi Local & Evening Varanasi / DDU Station
        Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Ayodhya/Chitrakoot - Gaya - Kashi <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival - Local Visit - Night
        Halt Ayodhya/Chitrakoot. <br />
        <b>2nd Day -</b> Morning Ayodhya/Chitrakoot Local Visit - Night Journey
        Gaya.
        <br />
        <b>3rd Day -</b> Gaya, Bodhgaya Local-Journey to Varanasi - Night Halt
        Varanasi. <br />
        <b>4th Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating - Night
        Halt Varanasi.
        <br />
        <b>5th Day -</b> Morning Varanasi Local & Evening Varanasi / DDU Station
        Drop.
      </>
    ),
  },
  {
    id: 3,
    question: "6 Days - 5 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Naimisharnayam - Ayodhya - Gaya - Kashi <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival - Local Visit - Night
        Halt Naimisharnayam.
        <br />
        <b>2nd Day -</b> Morning Naimisharnayam Local Visit- Night Halt Ayodhya.
        <br />
        <b>3rd Day -</b> Morning Ayodhya Local Visit - Night Journey Gaya.
        <br />
        <b>4th Day -</b> Gaya, Bodhgaya Local-Journey to Varanasi- Night Halt
        Varanasi.
        <br />
        <b>5th Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating- Night
        Halt Varanasi.
        <br />
        <b>6th Day -</b> Morning Varanasi Local & Evening Varanasi / DDU Station
        Drop.
      </>
    ),
  },
  {
    id: 4,
    question: "6 Days - 5 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Kashi - Gaya - Kolkata <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival -Local Visit -Night
        Halt Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating- Night
        Halt Varanasi.
        <br />
        <b>3rd Day -</b> Morning Varanasi Local Visit - Night Journey to Gaya.
        <br />
        <b>4th Day -</b> Morning Prayagraj Journey & Visit - Night Journey Agra.
        <br />
        <b>5th Day -</b> Gaya, Bodhgaya Local-Journey to Kolkata- Late Night
        Halt Kolkata.
        <br />
        <b>6th Day -</b> Kolkata Local Tour - Night Halt Kolkata. 6th Day -
        Kolkata / Howrah Station Drop.
      </>
    ),
  },
  {
    id: 5,
    question: "7 Days - 6 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Chitrakoot - Naimisharnayam - Ayodhya - Gaya - Kashi
        <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival -Local Visit -Night
        Halt Chitrakoot. <br />
        <b>2nd Day -</b> Morning Chitrakoot Visit - Night Halt Naimisharnayam.
        <br />
        <b>3rd Day -</b> Naimisharnayam Local Visit- Night Halt Ayodhya. <br />
        <b>4th Day -</b> Morning Ayodhya Local Visit - Night Journey Ga
        <br />
        <b>5th Day -</b> Gaya, Bodhgaya Local-Journey to Varanasi - Night Halt
        Varanasi.
        <br />
        <b>6th Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating- Night
        Halt Varanasi.
        <br />
        <b>7th Day -</b> Morning Varanasi Local & Evening Varanasi / DDU Station
        Drop.
      </>
    ),
  },
  {
    id: 6,
    question: "8 Days - 7 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Kashi - Gaya - Kolkata - Bhubaneswar - Puri - Konark <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival -Local Visit -Night
        Halt Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating- Night
        Halt Varanasi.
        <br />
        <b>3rd Day -</b> Morning Varanasi Local Visit - Night Journey to Gaya.
        <br />
        <b>4th Day -</b> Gaya, Bodhgaya Local-Journey to Kolkata - Late Night
        Halt Kolkata.
        <br />
        <b>5th Day -</b> Kolkata Local - Night Journey Bhubaneswar.
        <br />
        <b>6th Day -</b> Bhubaneswar Visit -Night Halt Bhubaneswar.
        <br />
        <b>7th Day -</b> Puri, Konark Visit -Night Halt Bhubaneswar.
        <br />
        <b>8th Day -</b> Bhubaneswar Station Drop.
      </>
    ),
  },
  {
    id: 7,
    question: "9 Days - 8 Night Package Tour Programme",
    answers: (
      <>
        Prayagraj - Kashi - Gaya - Deoghar Jharkhand- Kolkata - Bhubaneswar -
        Puri - Konark <br />
        <br />
        <b>1st Day -</b> Morning Prayagraj Station Arrival -Local Visit -Night
        Halt Kashi.
        <br />
        <b>2nd Day -</b> Morning Kashi Darshan & Ganga Aarti & Boating- Night
        Halt Varanasi.
        <br />
        <b>3rd Day -</b> Morning Varanasi Local Visit - Night Journey to Gaya.
        <br />
        <b>4th Day -</b> Gaya, Bodhgaya Local-Journey to Deoghar- Night Halt
        Deoghar.
        <br />
        <b>5th Day -</b> Baba Baijnath Dham Darshan - Night Halt Kolkata.
        <br />
        <b>6th Day -</b> Kolkata Local - Night Journey Bhubaneswar.
        <br />
        <b>7th Day -</b> Bhubaneswar Visit -Night Halt Bhubaneswar.
        <br />
        <b>8th Day -</b> Puri, Konark Visit -Night Halt Bhubaneswar.
        <br />
        <b>8th Day -</b> Bhubaneswar Station Drop.
      </>
    ),
  },
];

export default PrayagrajData;
