import React from "react";
import BtmNav from "../../BtmNav";
import Footer from "../../Footer";
import "../Kashi.css";
import UttarakhandData from "./UttarakhandData";
import UttarakhandContent from "./UttarakhandContent";
import UttarakhandImagData from "./UttarakhandImgData";
import MarqueeBottom from "../../MarqueeBottom";
import "../../../../node_modules/photoswipe/dist/photoswipe.css";
import "../../../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Uttarakhand = () => {
  return (
    <>
      <section id="indian">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect Uttarakhand Tour Package</h4>
            <h1 className="h1 h1-responsive title-secondary">
              Uttarakhand Tour
            </h1>
          </div>

          <div className="row mt-5 px-0 py-5">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                {UttarakhandData.map((curElem) => {
                  const { id } = curElem;
                  return <UttarakhandContent key={id} {...curElem} />;
                })}
              </div>
            </div>

            <div className="col-md-6">
              <div class="places-img-container">
                <Gallery>
                  {UttarakhandImagData.map((imgDat) => {
                    return (
                      <Item
                        original={imgDat.imgSrc}
                        thumbnail={imgDat.imgSrc}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={`${process.env.REACT_APP_CLOUDINARY_URL}${imgDat.imgSrc}`}
                            alt={imgDat.imgAlt}
                          />
                        )}
                      </Item>
                    );
                  })}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MarqueeBottom />
      <Footer />
      <BtmNav />
    </>
  );
};
export default Uttarakhand;
