import tempo from "../../../Images/vehicleimg/tempo traveller.jpg";
import minibus from "../../../Images/vehicleimg/Mini COach.jpg";
import cruiser from "../../../Images/vehicleimg/cruiser.jpg";
import Traveller20Seats from "../../../Images/vehicleimg/Traveller20Seats.jpg";
import Traveller26Seats from "../../../Images/vehicleimg/Traveller26Seats.jpg";
import MiniBus31Seats from "../../../Images/vehicleimg/MiniBus31Seats.jpg";

export const MiniCoachData = [
  {
    id: 1,
    placeName: "Tempo Traveller - 16+D, 17+D",
    imgSrc: tempo,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "tempo traveller",
  },
  {
    id: 5,
    placeName: "Tempo Traveller - 20 Seats",
    imgSrc: Traveller20Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 3,
    placeName: "Tempo Traveller - 26 Seats",
    imgSrc: Traveller26Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 3,
    placeName: "Mini Bus - 31 Seats",
    imgSrc: MiniBus31Seats,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
  {
    id: 2,
    placeName: "Bus - 2x2 - 35 Seats",
    imgSrc: minibus,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus ",
  },
  {
    id: 4,
    placeName: "Cruiser - 13+D",
    imgSrc: cruiser,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "bus",
  },
];
