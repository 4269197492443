import React from "react";

const DelhiData = [
  {
    id: 1,
    question: "8 Days - 7 Night Package Tour Programme",
    answers: (
      <>
        Delhi - Haridwar - Rishikesh - Mathura - Agra - Prayagraj - Kashi <br />
        <br />
        <b>1st Day -</b> Morning Delhi Station Received & Local-Halt.
        <br />
        <b>2nd Day -</b> Delhi to Haridwar-Halt.
        <br />
        <b>3rd Day -</b> Haridwar Local-Halt.
        <br />
        <b>4th Day -</b> Rishikesh Local & Journey to Mathura-Halt.
        <br />
        <b>5th Day -</b> Mathura, Agra Local -Night Journey Prayagraj.
        <br />
        <b>6th Day -</b> Prayagraj Local -Kashi - Halt.
        <br />
        <b>7th Day -</b> Kashi Darshan & Ganga Aarti Boating-Halt.
        <br />
        <b>8th Day -</b> Kashi Local & Evening DDU Station Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "10 Days - 9 Night Package Tour Programme",
    answers: (
      <>
        Delhi - Haridwar - Rishikesh - Mathura - Agra - Prayagraj - Ayodhya -
        Gaya - Kashi <br />
        <br />
        <b>1st Day -</b> Morning Delhi Station Received & Local-Halt.
        <br />
        <b>2nd Day -</b> Delhi to Haridwar-Halt.
        <br />
        <b>3rd Day -</b> Haridwar Local-Halt.
        <br />
        <b>4th Day -</b> Rishikesh Local & Journey to Mathura-Halt.
        <br />
        <b>5th Day -</b> Mathura, Agra Local -Night Journey Prayagraj.
        <br />
        <b>6th Day -</b> Prayagraj Local -Ayodhya - Halt.
        <br />
        <b>7th Day -</b> Ayodhya Local - Night Journey Gaya.
        <br />
        <b>8th Day -</b> Gaya, Bodhgaya Visit - Kashi- Halt.
        <br />
        <b>9th Day -</b> Kashi Darshan & Ganga Aarti Boating-Halt.
        <br />
        <b>10th Day -</b> Kashi Local & Evening DDU Station Drop.
      </>
    ),
  },
];

export default DelhiData;
