import React from "react";
import BtmNav from "../../BtmNav";
import Footer from "../../Footer";
import "../../placesknowmore/Kashi.css";
import "../food.css";
import SouthIndianFoodData from "./SouthIndianFoodData";
import SouthIndianFoodImagData from "./SouthIndianFoodImgData";
import MarqueeBottom from "../../MarqueeBottom";
import "../../../../node_modules/photoswipe/dist/photoswipe.css";
import "../../../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const SouthIndianFood = () => {
  return (
    <>
      <section id="indian-food">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect South Indian Food Package</h4>
            <h1 className="h1 h1-responsive title-secondary">
              South Indian Food
            </h1>
          </div>

          <div className="row mt-5 px-0 py-5">
            <div className="col-md-6">
              {SouthIndianFoodData.map((foodDat) => {
                return (
                  <>
                    <h4 style={{ color: "#ff6819" }} key={foodDat.id}>
                      {foodDat.supperHead}
                    </h4>
                    <p style={{ marginLeft: "50px" }}>
                      {foodDat.supperContent}
                    </p>
                  </>
                );
              })}
            </div>
            <div className="col-md-6">
              <div className="places-img-container">
                <Gallery>
                  {SouthIndianFoodImagData.map((imgDat) => {
                    return (
                      <Item
                        original={imgDat.imgSrc}
                        thumbnail={imgDat.imgSrc}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={imgDat.imgSrc}
                            alt={imgDat.imgAlt}
                          />
                        )}
                      </Item>
                    );
                  })}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MarqueeBottom />
      <Footer />
      <BtmNav />
    </>
  );
};
export default SouthIndianFood;
