const MaharastrianFoodData = [
    {
        id:1,
        supperHead: 'Breakfast -',
        supperContent:`Tea, Coffee, Poha-Lemon, Idali, Wada, Sambhar, Coconut
        Chatani,Rava Upma Chatani, Seviyaa Upma Chatani, Bread Pakoda,
        Chatani Bonda & Pakaudi Bhaaji Souce, Chuda Matar, Puri - Bhaaji,
        Pasta, Chowmine Sauce, Paav Bhaaji, Puri-Bhaaji, Aaloo Dum Puri,
        Aaloo Paratha, Matar Paratha, Souce, Masal Dosa with Chatani,
        Chana Masala, Puri and more...`
    },
    {
        id:2,
        supperHead: 'Lunch & Dinner -',
        supperContent:`Chapati, Fulka Roti, Papad, Chips, Pickle, Rice, Jeera Rice,
        Khichadi, Veg. Palao, Masala Rice, Veg Biryani, Bonda, Mirch
        Bhaaji, Pakaudi, Mirch Fry, Kadhi Pakoda, Daal, Daal Fry, Daal
        Masala, Chana Dal, Mix Veg Daal, Sambar, Paneer Masala, Kadahi
        Paneer, Matar Paneer, Bhindi Bhajji, Patta Godbhi, Gobhi, Lauki
        Bhaaji, Baigan(Brinjal) Veg., Aaloo Dam, Aaloo Masal, Chana Masal,
        Aaloo Jeera, Aaloo Paratha, Mix Veg., Palak Sabji, Potato Finger
        and many more...`
    },
    {
        id:3,
        supperHead: 'Evening Snacks -',
        supperContent:`Tea/Coffee & Biscuit/Namkeen`
    },
    {
        id:4,
        supperHead: 'Sweets -',
        supperContent:`Gulab Jamun, Sevaiya Kheer, Rice Kheer, Daliya Kheer, Kesari
        Jalebi, Balu Sahi, Lavanglata, Dahi Wada, Gajjar Halwa & more...`
    },

];

export default MaharastrianFoodData;