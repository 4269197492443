import React from "react";

const UttarakhandData = [
  {
    id: 1,
    question: "3 Days - 2 Night Package Tour Programme",
    answers: (
      <>
        Haridwar - Rishikesh - Haridwar <br />
        <br />
        <b>1st Day -</b> Morning Haridwar Arrival Local - Halt.
        <br />
        <b>2nd Day -</b> Haridwar to Rishikesh Visit - Haridwar Halt.
        <br />
        <b>3rd Day -</b> Haridwar Local & Haridwar Station Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "5 Days - 4 Night Package Tour Programme",
    answers: (
      <>
        {" "}
        Haridwar - Rishikesh - Haridwar <br />
        <br />
        <b>1st Day -</b> Morning Haridwar Arrival Local - Halt. <br />
        <b>2nd Day -</b> Haridwar to Badrinath - Halt.
        <br />
        <b>3rd Day -</b> Badrinath Temple Darshan - Joshimath Halt. <br />
        <b>4th Day -</b> Joshimath to Rishikesh Visit - Haridwar Halt.
        <br />
        <b>5th Day -</b> Haridwar Local & Haridwar Station Drop.
      </>
    ),
  },
];

export default UttarakhandData;
