import React from "react";
import Card from "./Card";
import { VehicleData } from "./VehicleData";

const Vehicle = () => {
  return (
    <section id="transport">
      <div className="container">
        <div className="title">
          <h4 className="title-primary">Perfect Transports for Tours</h4>
          <h1 className="h1 h1-responsive title-secondary">
            Featured Vehicles
          </h1>
        </div>
        <div className="row mt-5 px-0 py-5">
          {VehicleData.map((vehicles) => {
            return <Card places={vehicles} key={vehicles.id} />;
          })}
        </div>
      </div>
      <hr style={{ marginLeft: "8%", marginRight: "8%" }} />
    </section>
  );
};

export default Vehicle;
