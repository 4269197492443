import innova from "../../../Images/vehicleimg/innova.JPEG";
import innovaCrysta from "../../../Images/vehicleimg/INNOVA CRYSTA.jpg";
import swift from "../../../Images/vehicleimg/SWIFT.jpg";
import hondaCity from "../../../Images/vehicleimg/HONDA CITY.jpg";
import hondaAmaze from "../../../Images/vehicleimg/HONDA AMAZE.jpg";
import ertiga from "../../../Images/vehicleimg/ERTIGA.jpg";
// import tavera from "../../../Images/vehicleimg/tavera.jpg";

export const CarsData = [
  {
    id: 6,
    placeName: "Ertiga - 6+D, 7+D",
    imgSrc: ertiga,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "ertiga",
  },
  {
    id: 2,
    placeName: "Innova Crysta - 6+D, 7+D",
    imgSrc: innovaCrysta,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "innova crysta",
  },
  {
    id: 1,
    placeName: "Innova - 6+D, 7+D",
    imgSrc: innova,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "innova",
  },

  {
    id: 3,
    placeName: "Swift - 4+D",
    imgSrc: swift,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "swift",
  },
  {
    id: 4,
    placeName: "Honda City - 4+D",
    imgSrc: hondaCity,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "honda city",
  },
  {
    id: 5,
    placeName: "Honda Amaze - 4+D",
    imgSrc: hondaAmaze,
    btnTxt: "Book Now",
    btnLink: "/contact",
    imgAlt: "honda amaze",
  },

  // {
  //   id: 7,
  //   placeName: "Tavera - 7+D",
  //   imgSrc:tavera,
  //   btnTxt: "Book Now",
  //   btnLink: '/contact',
  //   imgAlt: "tavera"
  // },
];
