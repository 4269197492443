export const PlacesData = [
  {
    id: 1,
    placeName: "Kashi to All Pilgrims Place",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/VaranasiCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/kashi",
    imgAlt: "kashi tour",
  },
  {
    id: 7,
    placeName: "Kashi to Nepal Tour",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/KashiNepalCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/nepal",
    imgAlt: "nepal tour",
  },
  {
    id: 2,
    placeName: "Prayagraj to All Pilgrims Place",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/PrayagrajCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/prayagraj",
    imgAlt: "prayagraj tour",
  },
  {
    id: 3,
    placeName: "Haridwar Tour",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/HaridwarCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/uttarakhand",
    imgAlt: "haridwar tour",
  },
  {
    id: 4,
    placeName: "Dham Darshan",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/DhamCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/dham",
    imgAlt: "dham darshan",
  },
  {
    id: 5,
    placeName: "Delhi To Kashi",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/DelhiCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/delhi",
    imgAlt: "delhi tour",
  },
  {
    id: 6,
    placeName: "Agra-Mathura-Vrindavan via Kashi",
    imgSrc: `${process.env.REACT_APP_CLOUDINARY_URL}/AgraCardImg.jpg`,
    btnTxt: "Know More",
    btnLink: "/agra",
    imgAlt: "agra tour",
  },
];
