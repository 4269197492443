import React from "react";

const BtmNav = () => {
  return (
    <div id="divContactSnackBar" className="ContactSnackBar detail active">
      <ul>
        <li className="call-ico">
          <a href="tel:+918115588957" aria-label="Call Us">
            <span className="pkg-sprite callIcon">
              <i className="fas fa-phone-alt"> </i>
            </span>
            &nbsp; Call Us
          </a>
        </li>
        <li className="form-ico">
          <a
            href="https://wa.me/918115588957?text=Hello,%20I%20want%20to%20book%20a%20trip%20package%20with%20Sai%20Travel%20&%20Catering%20Services."
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="pkg-sprite callIcon">
              <i className="fab fa-whatsapp"></i>
            </span>
            &nbsp; Send Enquiry
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BtmNav;
