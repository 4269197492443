import React from "react";
import Card from "./Card";
import { PlacesData } from "./PlacesData";

const Places = () => {
  return (
    <>
      <section id="featured">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect Holiday</h4>
            <h1 className="h1 h1-responsive title-secondary">
              Featured Places
            </h1>
          </div>
          <div className="row mt-5 px-0 py-5">
            {PlacesData.map((places) => {
              return <Card places={places} key={places.id} />;
            })}
          </div>
          {/* <div className="text-center">
          <button
            className="btn shadow"
            style={{ backgroundColor: "#f94f0f", color: "#fff" }}
          >
            View All Tours
          </button>
        </div> */}
        </div>
      </section>
    </>
  );
};

export default Places;
