import React from "react";

const Food = () => {
  return (
    <section id="food">
      <div className="container">
        <div className="title">
          <h4 className="title-primary">Perfect Food Package For Your Tour</h4>
          <h1 className="h1 h1-responsive title-secondary">Food Packages</h1>
        </div>
        <div className="row mt-5 px-0 py-5">
          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div
                className="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/c_fill,ar_1:1/Indian.jpg`}
                  className="img-fluid"
                  alt="Indian"
                />
                <a href="indianfood" aria-label="Indian Food Details">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </div>
              <div className="card-body shadow">
                <h5 className="card-title" style={{ color: "#000" }}>
                  Indian Food Package
                </h5>
                <a
                  href="indianfood"
                  className="btn"
                  style={{ backgroundColor: "#ff6819", color: "#fff" }}
                  aria-label="Indian Food Details"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div
                className="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/c_fill,ar_1:1/SouthIndian.jpg`}
                  className="img-fluid"
                  alt="South Indian"
                />
                <a
                  href="/southindianfood"
                  aria-label="South Indian Food Details"
                >
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </div>
              <div className="card-body shadow">
                <h5 className="card-title" style={{ color: "#000" }}>
                  South Indian Food Package
                </h5>
                <a
                  href="/southindianfood"
                  className="btn"
                  style={{ backgroundColor: "#ff6819", color: "#fff" }}
                  aria-label="South Indian Food Details"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div
                className="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/c_pad,b_gen_fill,ar_1:1/Maharastrian.jpg`}
                  className="img-fluid"
                  alt="Maharastrian"
                />
                <a
                  href="maharastrianfood"
                  aria-label="Maharastrian Food Details"
                >
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </div>
              <div className="card-body shadow">
                <h5 className="card-title" style={{ color: "#000" }}>
                  Maharastrian Food Package
                </h5>
                <a
                  href="maharastrianfood"
                  className="btn"
                  style={{ backgroundColor: "#ff6819", color: "#fff" }}
                  aria-label="Maharastrian Food Details"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ marginLeft: "8%", marginRight: "8%" }} />
    </section>
  );
};

export default Food;
