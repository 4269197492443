import bus from '../../../Images/vehicleimg/52 seat bus.jpg';
import busb from '../../../Images/vehicleimg/BUS123.jpg';
import sBus from '../../../Images/vehicleimg/45seat bus.jpg';


export const LuxuryCoachData = [
    {
      id: 1,
      placeName: "Bus - 3x2 - 52 Seats",
      imgSrc: bus,
      btnTxt: "Book Now",
      btnLink: '/contact',
      imgAlt:"bus"
    },
    {
      id: 2,
      placeName: "Bus - 2x2 - 49 Seats",
      imgSrc: busb,
      btnTxt: "Book Now",
      btnLink: '/contact',
      imgAlt:"bus"
    },
    {
      id: 3,
      placeName: "Bus - 2x2 - 45 Seats",
      imgSrc: sBus,
      btnTxt: "Book Now",
      btnLink: '/contact',
      imgAlt:"bus"
    },   
  ];