import React from "react";

const InternationalTour = () => {
  return (
    <section id="international">
      <div className="container">
        <div className="title">
          <h4 className="title-primary">Perfect International Destination</h4>
          <h1 className="h1 h1-responsive title-secondary">
            International Tours
          </h1>
        </div>
        <div className="row mt-5 px-0 py-5">
          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div
                className="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src={`${process.env.REACT_APP_CLOUDINARY_URL}/NepalCard.jpg`}
                  className="img-fluid"
                  alt="Nepal"
                />
                <a href="/nepal" aria-label="Nepal Tour Details">
                  <div
                    className="mask"
                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                  ></div>
                </a>
              </div>
              <div className="card-body shadow">
                <h5 className="card-title" style={{ color: "#000" }}>
                  Nepal Tour
                </h5>
                <a
                  href="/nepal"
                  className="btn"
                  style={{ backgroundColor: "#ff6819", color: "#fff" }}
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ marginLeft: "8%", marginRight: "8%" }} />
    </section>
  );
};

export default InternationalTour;
