import React from "react";
import BtmNav from "./BtmNav";
import Footer from "./Footer";

const AboutUs = () => {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">
              Tours & Travels Agency In Varanasi
            </h4>
            <h1 className="h1 h1-responsive title-secondary">About Us</h1>
          </div>

          <div className="description">
            <p className="note note-warning mt-5">
              <strong>
                Sai Travels & Catering Services : [SMART SERVICE - HONEST PRICE]
              </strong>
              <br />
              <br />
              Sai Travels & Catering Service is a VARANASI [KASHI] based Tours,
              Travels & Catering Service Agency with 15 Years of Travels
              industry experience.
              <br />
              An amazing place to enjoy the best Vehicle & the best Food quality
              around your journey/Traveling. We served the food with touch of
              love.
              <br />
              Sai Travels & Catering Service provides best service 24*7 Hrs.
              <br />
              We provide Cars, Coach Rental, Bus[AC & Non-AC], Hotel
              Reservations, Guest House, Hall, Dharmshala, Group Tours, and Food
              Packages Services.
              <br />
              We offers our service all over India & Nepal at very reasonable
              charges. Travels plan of your choice.
              <br />
              In Varanasi we are best & reliable tours & travel service provide.
              <br />
              Tour Operators in Varanasi, Uttar Pradesh, India
              <br />
              We provide personalized and friendly Travel plans which results in
              repeated visits.
              <br />
              <br />
              <strong>
                Why Choose Us <i className="fas fa-question"></i>
              </strong>
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; We have
              competitive Rates for Bus, Cars, Hotels & Food Booking.
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; We are
              operational around the clock <i style={{ color: "#000" }}>24*7</i>
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; We have the
              best service team.
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; Fast & Easy
              Booking
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; Always ready
              for your service.
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; Service that
              value for money.
              <br />
              <i className="fas fa-hand-point-right"></i> &nbsp; We provide the
              regional best food & catering service.
              <figure className="text-end">
                <br />
                <figcaption
                  className="blockquote-footer"
                  style={{ color: "#000", fontSize: "16px" }}
                >
                  Thank you & Have a Marvelous Journey...
                  <i className="far fa-grin-beam"></i>
                  <br />
                  <cite title="Source Title">
                    by Sai Travels & Catering Services
                  </cite>
                </figcaption>
              </figure>
            </p>
          </div>
        </div>
      </section>
      <Footer />
      <BtmNav />
    </>
  );
};

export default AboutUs;
