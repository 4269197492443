import React from "react";

const Footer = () => {
  return (
    <footer class="bg-dark text-center text-white" id="contact">
      <div class="container p-4">
        <section class="mb-4">
          <a
            class="btn btn-primary btn-floating m-1"
            style={{ backgroundColor: "#3b5998" }}
            href="https://www.facebook.com/himjais74"
            role="button"
            aria-label="Facebook"
          >
            <i class="fab fa-facebook-f"></i>
          </a>

          <a
            class="btn btn-primary btn-floating m-1"
            style={{ backgroundColor: "#dd4b39" }}
            href="https://youtube.com/channel/UC70Iyl4ii489vUlbGvzSVqQ"
            role="button"
            aria-label="Youtube"
          >
            <i class="fab fa-youtube"></i>
          </a>

          <a
            class="btn btn-primary btn-floating m-1"
            style={{ backgroundColor: "#ac2bac" }}
            href="https://instagram.com/saitravel_cateringservices?utm_medium=copy_link"
            role="button"
            aria-label="Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>

          <a
            className="btn btn-primary btn-floating m-1"
            style={{ backgroundColor: "#25D366" }}
            href="https://wa.me/918115588957?text=Hello,%20I%20want%20to%20book%20a%20trip%20package%20with%20Sai%20Travel%20&%20Catering%20Services."
            target="_blank"
            role="button"
            rel="noopener noreferrer"
            aria-label="Whatsapp"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
        </section>

        {/* <section class="">
          <form action="">
            <div class="row d-flex justify-content-center">
              <div class="col-auto">
                <p class="pt-2">
                  <strong>Subscribe for receiving Best Offers</strong>
                </p>
              </div>

              <div class="col-md-5 col-12">
                <div class="form-outline form-white mb-4">
                  <input type="email" id="form5Example2" class="form-control" />
                  <label class="form-label" for="form5Example2">
                    Email address
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <button type="submit" class="btn btn-outline-light mb-4">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </section> */}

        <section class="">
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase">Need Help ?</h5>

              <ul class="list-unstyled mb-0">
                <li>
                  <h6>Working Hours: 24*7 Hrs (Everyday)</h6>
                </li>
                <li>
                  <h6>
                    Contact Number:
                    <a href="tel:+917499999755" aria-label="Call Us">
                      +91 7499999755
                    </a>
                    ,{" "}
                    <a href="tel:+919919047413" aria-label="Call Us">
                      +91 9919047413
                    </a>
                    ,{" "}
                    <a href="tel:+918115588957" aria-label="Call Us">
                      +91 8115588957
                    </a>
                  </h6>
                </li>
                <li>
                  <h6>
                    Address:
                    <a
                      href="https://goo.gl/maps/GxG6LxKaYAzrxp1G8"
                      aria-label="Address"
                    >
                      D.35/91-A, Pyari Durga Complex (Near Hotel Vibhav Harsh)
                      Jangambari, Varanasi (Uttar Pradesh) - India
                    </a>
                  </h6>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase">About</h5>

              <ul class="list-unstyled mb-0">
                <li>
                  <a href="/aboutus" class="text-white" aria-label="About Us">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/#featured" class="text-white" aria-label="Tours">
                    Tours Package in India
                  </a>
                </li>
                <li>
                  <a
                    href="/#international"
                    class="text-white"
                    aria-label="Tours Nepal"
                  >
                    Tours Package in Nepal
                  </a>
                </li>
                <li>
                  <a
                    href="/#transport"
                    class="text-white"
                    aria-label="Transport"
                  >
                    Transport
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase">Customer Support</h5>

              <ul class="list-unstyled mb-0">
                <li>
                  <a href="/contact" class="text-white">
                    Contact Us
                  </a>
                </li>
                {/* <li>
                  <a href="#payment" class="text-white">
                    Payment Options
                  </a>
                </li>
                <li>
                  <a href="#pricing" class="text-white">
                    Lowest Rates Challenge
                  </a>
                </li>*/}
                <li>
                  <a
                    href="/#Testimonial"
                    class="text-white"
                    aria-label="Testimonial"
                  >
                    Testimonials
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <div
        class="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 Sai Travels & Catering Services | All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
