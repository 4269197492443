import React from "react";
import Banner from "./Banner";
import BtmNav from "./BtmNav";
import Food from "./Food";
import Footer from "./Footer";
import InternationalTour from "./InternationalTour";
import Places from "./Places";
import Reasons from "./Reasons";
import Showcase from "./Showcase";
import Vehicle from "./Vehicle";
import TestimonialsPage from "./Testimonialsection";

const Home = () => {
  return (
    <>
      <Banner />
      <Places />
      <Showcase />
      <Vehicle />
      <InternationalTour />
      <Food />
      <Reasons />
      <TestimonialsPage />
      <div
        class="elfsight-app-e74f7c18-3e8e-4ad5-9fea-5160d14bfa8e"
        data-elfsight-app-lazy
      ></div>
      <Footer />
      <BtmNav />
      <div
        class="elfsight-app-ba1c8dac-58c4-4cc5-a98b-fe03020b467e"
        data-elfsight-app-lazy
      ></div>
    </>
  );
};

export default Home;
