import React from "react";

const Card = (props) => {
  return (
    <div className="col-lg-4 px-4 my-3">
      <div className="card">
        <div
          className="bg-image hover-overlay ripple"
          data-mdb-ripple-color="light"
        >
          <img
            src={props.places.imgSrc}
            alt={props.places.imgAlt}
            className="img-fluid"
          />
          <a href={props.places.btnLink} aria-label="View more images">
            <div
              className="mask"
              style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
            ></div>
          </a>
        </div>
        <div className="card-body shadow">
          <h5 className="card-title" style={{ color: "#000" }}>
            {props.places.placeName}
          </h5>
          <a
            href={props.places.btnLink}
            className="btn"
            style={{ backgroundColor: "#ff6819", color: "#fff" }}
          >
            {props.places.btnTxt}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
