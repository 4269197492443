const IndianFoodData = [
    {
        id:1,
        supperHead: 'Breakfast -',
        supperContent:`Puri Sabji, Bread Pakauda, Pakaudi, Aalu Bonda Chatani, Chana
        Masala, Idali, Wada, Sambhar, Chatani, Chaumin, Pasta, Manchurian
        Rice, Sauce, Chuda Matar, Gajar Halwa, Poha and more...`
    },
    {
        id:2,
        supperHead: 'Lunch & Dinner -',
        supperContent:`Roti, Chola, Rice, Pickle, Vegetable, Chips, Curd, Puri, Jeera
        Rice, Chana Dal Wada, Veg Biryani, LadyFinger Veg, Brinjal Aalu,
        Mix Veg, Palak Daal, Daal Fry Masala, Chana Masala, Cauli Flower
        Veg., Cabbage Veg., Beetroot gourd Veg., Saahi Paneer, Matar
        Paneer, Kadahi Paneer, Aalu Dum, Tomato Chatani, Brinjal Kalauji,
        Aalu (Potato) Jeera, and many more...`
    },
    {
        id:3,
        supperHead: 'Evening Snacks -',
        supperContent:`Tea/Coffee & Biscuit/Namkeen`
    },
    {
        id:4,
        supperHead: 'Sweets -',
        supperContent:`Gulab Jamun, Sevaiya Kheer, Rice Kheer, Daliya Kheer, Kesari
        Jalebi, Balu Sahi, Lavanglata, Dahi Wada, Gajjar Halwa & more...`
    },

];

export default IndianFoodData;