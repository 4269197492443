import React from "react";

const DhamData = [
  {
    id: 1,
    question: "6 Days - 5 Night Package Tour Programme",
    answers: (
      <>
        Do Dham Darshan <br />
        <br />
        <b>1st Day -</b> Haridwar to Guptkashi/Phata - Halt.
        <br />
        <b>2nd Day -</b> Guptkashi/Phata - Gaurikund - Kedarnath - Halt.
        <br />
        <b>3rd Day -</b> Kedaranath - Gaurikund - Phata -Halt.
        <br />
        <b>4th Day -</b> Phata - Joshimath - Badrinath - Halt.
        <br />
        <b>5th Day -</b> Badrinath - Srinagar - Halt.
        <br />
        <b>6th Day -</b> Srinagar - Rishikesh - Haridwar Drop.
      </>
    ),
  },
  {
    id: 2,
    question: "12 Days - 11 Night Package Tour Programme",
    answers: (
      <>
        Char Dham Darshan <br />
        <br />
        <b>1st Day -</b> Morning Haridwar Railway Station Receiving & Haridwar
        Local - Halt Haridwar.
        <br />
        <b>2nd Day -</b> Morning Haridwar to Barkot Journey - Halt Barkot.
        <br />
        <b>3rd Day -</b> Barkot to Yamunotri Return Barkot - Halt Barkot.
        <br />
        <b>4th Day -</b> Barkot to Uttarkashi - Halt Uttarkashi.
        <br />
        <b>5th Day -</b> Uttarkashi to Gangotri Return Uttarkashi - Halt
        Uttarkashi.
        <br />
        <b>6th Day -</b> Uttarkashi to Sitapur - Halt Sitapur.
        <br />
        <b>7th Day -</b> Sitapur to Kedarnath - Halt Kedarnath.
        <br />
        <b>8th Day -</b> Kedarnath to Sitapur - Halt Sitapur.
        <br />
        <b>9th Day -</b> Sitapur to Pipalkoti- Halt Pipalkoti.
        <br />
        <b>10th Day -</b> Pipalkoti to Badrinath to Pipalkoti- Halt Pipalkoti.
        <br />
        <b>11th Day -</b> Pipalkoti to Haridwar- Halt Haridwar.
        <br />
        <b>12th Day -</b> Morning Haridwar St. Drop.
      </>
    ),
  },
];

export default DhamData;
