import React from "react";
import ContactSVG from "../Images/contact.svg";
import MessageSVG from "../Images/message.svg";
import TravelSVG from "../Images/traveler-with-a-suitcase.svg";

const Reasons = () => {
  return (
    <section id="reasons">
      <div className="container">
        <div className="title">
          <h4 className="title-primary">
            Some Good Reasons to Book With Sai Travels
          </h4>
          <h1 className="h1 h1-responsive title-secondary">Book With Us</h1>
        </div>

        <div className="row mt-5 px-0 py-5">
          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div className="card-body shadow text-center">
                <img
                  src={TravelSVG}
                  className="img-fluid"
                  width="100"
                  height="100"
                  alt="Travel Agents in Varanasi"
                  style={{ margin: "5px" }}
                />
                <h5 className="card-title" style={{ color: "#000" }}>
                  <span style={{ color: "#ff6819" }}>+2500</span> Premium tours
                </h5>
                <p className="card-text">
                  We organize customized tours packages, both domestic and
                  international, for Families as well as Big Groups. Just let us
                  know your requirement we are here for everything.
                </p>
                <a href="#featured" aria-label="Read More">
                  <button
                    type="button"
                    className="btn"
                    style={{ border: "2px solid #ff6819", color: "#ff6819" }}
                  >
                    Read More
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div className="card-body shadow text-center">
                <img
                  src={MessageSVG}
                  className="img-fluid"
                  width="100"
                  height="100"
                  alt="Travel Agents in Varanasi"
                  style={{ margin: "5px" }}
                />
                <h5 className="card-title" style={{ color: "#000" }}>
                  <span style={{ color: "#ff6819" }}>+50,000</span> Customers
                </h5>
                <p className="card-text">
                  Customer satisfaction is Prime for us , Our strength is that
                  more than 70% business is through repeat customers . You can
                  see our customers feedbacks in our Testimonial.
                </p>
                <a href="#Testimonial" aria-label="Testimonials">
                  <button
                    type="button"
                    className="btn"
                    style={{ border: "2px solid #ff6819", color: "#ff6819" }}
                  >
                    Testimonials
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 my-3">
            <div className="card">
              <div className="card-body shadow text-center">
                <img
                  src={ContactSVG}
                  className="img-fluid"
                  width="100"
                  height="100"
                  alt="Travel Agents in Varanasi"
                  style={{ margin: "5px" }}
                />
                <h5 className="card-title" style={{ color: "#000" }}>
                  <span style={{ color: "#ff6819" }}>Contact</span> Details
                </h5>
                <p className="card-text">
                  <small className="text-muted">24*7, Monday - Sunday</small>
                  <br />
                  <small className="text-muted">Email: &nbsp;</small>
                  <a
                    href="mailto:himjais7@gmail.com"
                    style={{ color: "#000" }}
                    aria-label="Email Us"
                  >
                    himjais7@gmail.com
                  </a>
                  <br />
                  <small className="text-muted">Phone: &nbsp;</small>
                  <a
                    href="tel:+917499999755"
                    style={{ color: "#000" }}
                    aria-label="Call Us"
                  >
                    +91 7499999755
                  </a>
                  ,{" "}
                  <a
                    href="tel:+919919047413"
                    style={{ color: "#000" }}
                    aria-label="Call Us"
                  >
                    +91 9919047413
                  </a>
                  ,<br />
                  <a
                    href="tel:+918115588957"
                    style={{ color: "#000" }}
                    aria-label="Call Us"
                  >
                    +91 8115588957
                  </a>
                </p>
                <a href="/contact" aria-label="Contact Us">
                  <button
                    type="button"
                    className="btn"
                    style={{ border: "2px solid #ff6819", color: "#ff6819" }}
                  >
                    Contact
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ marginLeft: "8%", marginRight: "8%" }} />
    </section>
  );
};

export default Reasons;
