/* eslint-disable jsx-a11y/no-distracting-elements */

import React from "react";

const MarqueeBottom = () => {
  const MarqueeStop = () => {
    return document.getElementById("mymarqueeb").stop();
  };
  const MarqueeStart = () => {
    return document.getElementById("mymarqueeb").start();
  };
  return (
    <div style={{ backgroundColor: "#e84118", color: "#fff" }}>
      <marquee
        id="mymarqueeb"
        class="li"
        direction="”right”"
        onMouseOver={MarqueeStop}
        onMouseOut={MarqueeStart}
        style={{ marginTop: "2px" }}
      >
        We also provide customized tour plan as your requirements. Please
        contact us to know more - &nbsp; <i class="fas fa-phone-alt"></i> &nbsp;
        <a
          href="tel:+917499999755"
          style={{ color: "#fff" }}
          aria-label="Call Us"
        >
          +91 7499999755
        </a>
        ,{" "}
        <a
          href="tel:+919919047413"
          style={{ color: "#fff" }}
          aria-label="Call Us"
        >
          +91 9919047413
        </a>
        ,{" "}
        <a
          href="tel:+918115588957"
          style={{ color: "#fff" }}
          aria-label="Call Us"
        >
          +91 8115588957
        </a>
      </marquee>
    </div>
  );
};

export default MarqueeBottom;
