import alluSbji from '../../../Images/maharastrianFood/alluSbji.jpeg';
import channa from '../../../Images/maharastrianFood/channa.jpeg';
import chapati from '../../../Images/maharastrianFood/chapati.jpeg';
import collage from '../../../Images/maharastrianFood/collage.jpeg';
import collage2 from '../../../Images/maharastrianFood/collage2.jpeg';
import collage3 from '../../../Images/maharastrianFood/collage3.jpeg';
import dalFry from '../../../Images/maharastrianFood/dalFry.jpeg';
import dalFry1 from '../../../Images/maharastrianFood/dalFry1.jpeg';
import jeeraRice from '../../../Images/maharastrianFood/jeeraRice.jpeg';
import mixSbji2 from '../../../Images/maharastrianFood/mixSbji2.jpeg';
import mixVegRassa from '../../../Images/maharastrianFood/mixVegRassa.jpeg';
import rice from '../../../Images/maharastrianFood/rice.jpeg';
import sabji from '../../../Images/maharastrianFood/sabji.jpeg';
import suji from '../../../Images/maharastrianFood/suji.jpeg';
import thali from '../../../Images/maharastrianFood/thali.jpeg';
import rasagulla from '../../../Images/maharastrianFood/rasagulla.jpeg';
import jammunRassgulla from '../../../Images/maharastrianFood/jammunRassgulla.jpeg';
import jalebi from '../../../Images/maharastrianFood/jalebi.jpeg';
import gulabJamun from '../../../Images/maharastrianFood/gulabJamun.jpeg';


const MaharastrianFoodImagData = [
    {
        id:1,
        imgSrc:alluSbji,
        imgAlt:"allu sabji"
    },
    {
        id:2,
        imgSrc:channa,
        imgAlt:"Channa"
    },
    {
        id:3,
        imgSrc:chapati,
        imgAlt:"Chapati"
    },
    {
        id:4,
        imgSrc:collage,
        imgAlt:"collage"
    },
    {
        id:5,
        imgSrc:collage2,
        imgAlt:"collage 2"
    },
    {
        id:6,
        imgSrc:dalFry,
        imgAlt:"dal fry"
    },
    {
        id:7,
        imgSrc:dalFry1,
        imgAlt:"dal fry 1"
    },
    {
        id:8,
        imgSrc:collage3,
        imgAlt:"collage 3"
    },
    {
        id:9,
        imgSrc:jeeraRice,
        imgAlt:"jeera rice"
    },
    {
        id:10,
        imgSrc:mixSbji2,
        imgAlt:"mix sabji"
    },
    {
        id:11,
        imgSrc:mixVegRassa,
        imgAlt:"mix veg rassa"
    },
    {
        id:12,
        imgSrc:rice,
        imgAlt:"rice"
    },
    {
        id:13,
        imgSrc:sabji,
        imgAlt:"sabji"
    },
    {
        id:14,
        imgSrc:suji,
        imgAlt:"suji"
    },
    {
        id:15,
        imgSrc:thali,
        imgAlt:"thali"
    },
    {
        id:16,
        imgSrc:rasagulla,
        imgAlt:"rasgulla"
    },
    {
        id:17,
        imgSrc:jammunRassgulla,
        imgAlt:"jamun rasgulla"
    },
    {
        id:18,
        imgSrc:jalebi,
        imgAlt:"jalebi"
    },
    {
        id:19,
        imgSrc:gulabJamun,
        imgAlt:"gulab jamun"
    },
]

export default MaharastrianFoodImagData;