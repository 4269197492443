const SouthIndianFoodData = [
    {
        id:1,
        supperHead: 'Breakfast -',
        supperContent:`Tea, Coffee, Idali, Wada, Sambhar, Chatani Dosa, Potato Masal,
        Rava Upma, Chatani Seviyaa Upma, Chatani Puri Sabji, Chana masala,
        Puri, Bread Pakoda, Chatani, Sambhar Rics, Curd Rice, Lemon Rice,
        Pasta Sauce, Chowmine Sauce, Chuda Matar & Pickle.`
    },
    {
        id:2,
        supperHead: 'Lunch & Dinner -',
        supperContent:`Rice, Sambhar, Rasam, Moor, Pickle, Vegetable, Chips, Curd, Roti,
        Puri, Jeera Rice, Chana Dal Wada, Lemon Rice, Veg Biryani, Curd
        Rice, Dahi Pachadi, Mix Sambhar,Vendkaai Sambhar, Brinjal Sambhar,
        Carrot Sambhar, Raddish Sambhar, Palak Pappu Daal, Daal Fry
        Masala, Chana Masala, Mix Veg, Cauli Flower Veg., Cabbage Veg.,
        Beetroot gourd Veg., Brinjal Veg., Saahi Paneer, Matar Paneer,
        Kadahi Paneer, Aalu Dum, Paalak Kuttu, Tomato Chatani, Kaar
        Kolambo, Moor Kolabo, Aalu Jeera, Potato Finger and many more...`
    },
    {
        id:3,
        supperHead: 'Evening Snacks -',
        supperContent:`Tea/Coffee & Biscuit/Namkeen`
    },
    {
        id:4,
        supperHead: 'Sweets -',
        supperContent:`Gulab Jamun, Sevaiya Kheer, Rice Kheer, Daliya Kheer, Kesari
        Jalebi, Balu Sahi, Lavanglata, Dahi Wada, Gajjar Halwa & more...`
    },

];

export default SouthIndianFoodData;