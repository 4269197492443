import chowmine from '../../../Images/indianFood/chowmine.jpg';
import chudamatar from '../../../Images/indianFood/chudaMatar.jpg';
import collage2 from '../../../Images/indianFood/collage2.jpg';
import collage4 from '../../../Images/indianFood/collage4.jpg';
import collage5 from '../../../Images/indianFood/collage5.jpg';
import collagewithpeople from '../../../Images/indianFood/collageWithPeople.jpg';
import collage3 from '../../../Images/indianFood/collgage3.jpg';
import food4Collahe from '../../../Images/indianFood/food4Collahe.jpg';
import food5collage from '../../../Images/indianFood/food5Collage.jpg';
import food5collage1 from '../../../Images/indianFood/food5Collage1.jpg';
import foodcollage from '../../../Images/indianFood/foodCollage.jpg';
import foodcollage2 from '../../../Images/indianFood/foodCollage2.jpg';
import gajar from '../../../Images/indianFood/gajarHalwa.jpg';
import IndianThali from '../../../Images/indianFood/IndianThali.jpg';
import poha from '../../../Images/indianFood/poha.jpg';
import ssIndianThali from '../../../Images/indianFood/ssIndianThali.jpg';
const IndianFoodImagData = [
    {
        id:1,
        imgSrc:chowmine,
        imgAlt:"chowmine"
    },
    {
        id:2,
        imgSrc:chudamatar,
        imgAlt:"chuda matar"
    },
    {
        id:3,
        imgSrc:collage2,
        imgAlt:"dummy"
    },
    {
        id:4,
        imgSrc:collage4,
        imgAlt:"img4"
    },
    {
        id:5,
        imgSrc:collage5,
        imgAlt:"img 4"
    },
    {
        id:6,
        imgSrc:collagewithpeople,
        imgAlt:"img 5"
    },
    {
        id:7,
        imgSrc:collage3,
        imgAlt:"img 6"
    },
    {
        id:8,
        imgSrc:food4Collahe,
        imgAlt:"img 4"
    },
    {
        id:9,
        imgSrc:food5collage,
        imgAlt:"img 8"
    },
    {
        id:10,
        imgSrc:food5collage1,
        imgAlt:"img 7"
    },
    {
        id:11,
        imgSrc:foodcollage,
        imgAlt:"food mimg"
    },
    {
        id:12,
        imgSrc:foodcollage2,
        imgAlt:"food img"
    },
    {
        id:13,
        imgSrc:gajar,
        imgAlt:"gajar halwa"
    },
    {
        id:14,
        imgSrc:IndianThali,
        imgAlt:"indian thali"
    },
    {
        id:15,
        imgSrc:poha,
        imgAlt:"poha "
    },
    {
        id:16,
        imgSrc:ssIndianThali,
        imgAlt:"ss indian thali"
    },
]

export default IndianFoodImagData;