const AgraImagData = [
  {
    id: 1,
    imgSrc: "/AgraImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "/AgraImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "/AgraImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "/AgraImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "/AgraImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "/AgraImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "/AgraImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "/AgraImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "/AgraImg9.jpg",
    imgAlt: "img9",
  },
];

export default AgraImagData;
