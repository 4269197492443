import busImage from "../Images/Bus Image.jpeg";
import carImage from "../Images/Mini COach.jpg";
import carWhite from "../Images/Car Image.jpg";

export const VehicleData = [
  {
    id: 1,
    placeName: "Cars",
    imgSrc: carWhite,
    btnTxt: "Know More",
    btnLink: "/cars",
    imgAlt: "car",
  },
  {
    id: 2,
    placeName: "Mini Coach",
    imgSrc: carImage,
    btnTxt: "Know More",
    btnLink: "/minicoach",
    imgAlt: "mini coach",
  },
  {
    id: 3,
    placeName: "Luxury Coach",
    imgSrc: busImage,
    btnTxt: "Know More",
    btnLink: "/luxurycoach",
    imgAlt: "luxury coach",
  },
];
