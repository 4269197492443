import React from "react";
import BtmNav from "../../BtmNav";
import Footer from "../../Footer";
import "../Kashi.css";
import KashiData from "./KashiData";
import KashiContent from "./KashiContent";
import KashiImagData from "./KashiImgData";
import "../../../../node_modules/photoswipe/dist/photoswipe.css";
import "../../../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Kashi = () => {
  return (
    <>
      <section id="indian">
        <div className="container">
          <div className="title">
            <h4 className="title-primary">Perfect Kashi Tour Package</h4>
            <h1 className="h1 h1-responsive title-secondary">Kashi Tour</h1>
          </div>

          <div className="row mt-5 px-0 py-5">
            <div className="col-md-6">
              <div className="accordion" id="accordionExample">
                {KashiData.map((curElem) => {
                  const { id } = curElem;
                  return <KashiContent key={id} {...curElem} />;
                })}
              </div>
            </div>

            <div className="col-md-6">
              <div class="places-img-container">
                <Gallery>
                  {KashiImagData.map((imgDat) => {
                    return (
                      <Item
                        original={imgDat.imgSrc}
                        thumbnail={imgDat.imgSrc}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={`${process.env.REACT_APP_CLOUDINARY_URL}${imgDat.imgSrc}`}
                            alt={imgDat.imgAlt}
                          />
                        )}
                      </Item>
                    );
                  })}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BtmNav />
    </>
  );
};
export default Kashi;
