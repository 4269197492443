import React from 'react';
import BtmNav from '../../BtmNav';
import Footer from '../../Footer';
import Card from '../../Card';
import { LuxuryCoachData } from './LuxuryCoachData';
import '../cars.css';

const LuxuryCoach = () => {
    return(
        <>
    <section id="miniCoach">
      <div className="container">
        <div className="title">
          <h4 className="title-primary">Perfect Luxury Coach for Tours</h4>
          <h1 className="h1 h1-responsive title-secondary">Featured Luxury Coach</h1>
        </div>
        <div className="row mt-5 px-0 py-5">
          {LuxuryCoachData.map((cars) => {
            return <Card places={cars} key={cars.id} />;
          })}
        </div>
      </div>
    </section>
        <Footer />
        <BtmNav />
        </>
    );
};
export default LuxuryCoach;