const DhamImagData = [
  {
    id: 1,
    imgSrc: "DhamImg1.jpg",
    imgAlt: "img1",
  },
  {
    id: 2,
    imgSrc: "DhamImg2.jpg",
    imgAlt: "img2",
  },
  {
    id: 3,
    imgSrc: "DhamImg3.jpg",
    imgAlt: "img3",
  },
  {
    id: 4,
    imgSrc: "DhamImg4.jpg",
    imgAlt: "img4",
  },
  {
    id: 5,
    imgSrc: "DhamImg5.jpg",
    imgAlt: "img5",
  },
  {
    id: 6,
    imgSrc: "DhamImg6.jpg",
    imgAlt: "img6",
  },
  {
    id: 7,
    imgSrc: "DhamImg7.jpg",
    imgAlt: "img7",
  },
  {
    id: 8,
    imgSrc: "DhamImg8.jpg",
    imgAlt: "img8",
  },
  {
    id: 9,
    imgSrc: "DhamImg9.jpg",
    imgAlt: "img9",
  },
];

export default DhamImagData;
